import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Sidebar from "../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"
import PageBlock from "../../components/major-pieces/pageblock/PageBlock"
import ERIPageBlocks from "../../components/major-pieces/pageblock/ERIPageBlocks"
import getPageBlockFromData from "../../components/major-pieces/pageblock/PageBlock"
import CovidPlan from "../../../static/pdf/school/School-Covid-Plan-Update-11-9-20.pdf"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/atrium.css"
import "../../styles/header.css"

import JSONData from "../../../content/atrium/data.json"
import RegistrationForm from '../../../static/pdf/cgs/CGS Level 1 Registration 2021.pdf';

function ERI () {
	
	
	const query = graphql`
	      query {
	        allFile(
	          sort: { fields: name, order: ASC }
	          filter: { relativePath: { glob: "images/pages/atrium/*.*" } }
	        ) {
	          edges {
	            node {
	              id
	              name
	              childImageSharp {
	                fluid {
	                  ...GatsbyImageSharpFluid
	                }
	              }
	            }
	          }
	        }
	        
			}
	`
	let data = useStaticQuery(query)
	let images = data.allFile
	function addImage(item,images) {
		if(item.hasOwnProperty("img") && !!item.img){
			const img = images.edges.find(element => element.node.name === item.img)
			if(!!img && img.hasOwnProperty("node")){
				item.img = img.node
			}
		}
		return item
	}



	let sidebarData = JSONData.sidebarTextblocks;

	sidebarData = sidebarData.map(item => addImage(item,images))

	let links = JSONData.links;
	let subheaderData = JSONData.subheader; 

	
	const sidebarImage = images.edges.find(element => element.node.name === 'sidebar').node
	const mainphoto = images.edges.find(element => element.node.name === 'polson').node
	const scripture = images.edges.find(element => element.node.name ==='scripture').node
	const calendar = images.edges.find(element => element.node.name ==='wheel').node

	const liturgy = images.edges.find(element => element.node.name ==='cathrine').node

	const prayer = images.edges.find(element => element.node.name ==='3_boys').node

	const hands_on = images.edges.find(element => element.node.name ==='self_directed').node
	const self_directed = images.edges.find(element => element.node.name ==='reading').node
	const playful = images.edges.find(element => element.node.name ==='playful').node

	console.log("JSONData for school: ")
	console.log(JSONData)


	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content atrium">

				<div className = "atrium-main-area">
					<Subheader data={subheaderData} />
				</div>
				<Sidebar pageHome="true" textData={sidebarData} links={links} image={sidebarImage}/>
				<div className = "atrium-main-area">
					<div className="main-photo-wrapper">
						<Img
		           		className="main-photo"
		           		fluid={mainphoto.childImageSharp.fluid}
		               	alt="" >
		               	
		               	</Img>
		               	<div className="main-photo-caption">

		               	<span>"CGS is a common religious experience involving children and adults in which the religious values of childhood, primarily those values of contemplation and enjoyment of God, are predominant. This experience is shared in a place particularly prepared for the religious life of children called the Atrium."</span> - <b><a href="https://www.cgsusa.org" target="_blank">www.cgsusa.org</a></b>
		               	</div>

		            </div>
		           
					<br/>
		            	<div className="fundamentals">
		               		
		               		<h2>This Orthodox adaptation of <span>Catechesis of the Good Shepherd </span> introduces children to these essential aspects of Orthodox Christian life...
		               		</h2>

		               		<div className ="fundamental">
								<Img
				           		className="fundamental-photo"
				           		fluid={scripture.childImageSharp.fluid}
				               	alt="" />
				               	<div className="label">Scripture</div>
		               		</div>
		               		<div className ="fundamental">
								<Img
				           		className="fundamental-photo"
				           		fluid={liturgy.childImageSharp.fluid}
				               	alt="" />
				               	<div className="label">Liturgy</div>

		               		</div>
		               		
		               	</div>
		         		<div className="fundamentals">
							<div className ="fundamental">
								<Img
				           		className="fundamental-photo"
				           		fluid={calendar.childImageSharp.fluid}
				               	alt="" />
				               		<div className="label">Feasts</div>

		               		</div>

		               		<div className ="fundamental">
								<Img
				           		className="fundamental-photo"
				           		fluid={prayer.childImageSharp.fluid}
				               	alt="" />
				               	<div className="label">Prayer</div>

		               		</div>

		               	</div>

		               	<div className="methods">
		               		<h2>Catechesis of the Good Shepherd is a method that fully engages children in the Life of God and the Church.
		               		</h2>
		               		<div className ="method">
								<Img
				           		className="method-photo"
				           		fluid={self_directed.childImageSharp.fluid}
				               	alt="" />
				               	<div className="label">Self-Directed</div>

		               		</div>
		               		<div className ="method">
								<Img
				           		className="method-photo"
				           		fluid={hands_on.childImageSharp.fluid}
				               	alt="" />
				               	<div className="label">Hands-On</div>

		               		</div>
		               		<div className ="method">
								<Img
				           		className="method-photo"
				           		fluid={playful.childImageSharp.fluid}
				               	alt="" />
				               	<div className="label">Prayerful</div>

		               		</div>

		               	</div>
		               	<div className="video-wrapper">
		               		<h2 className="video-header">Video by Parish Coordinator, Formation Leader Shelley Finkler<br/>shelleyfinkler@gmail.com</h2>
		               		<iframe src="https://player.vimeo.com/video/503202496" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>

		        		</div>
		        </div>
				
			</main>
			<Footer />		
		</div>
		)
}

export default ERI 